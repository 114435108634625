

#Requests {
    display: flex;
    width: 100%;
}

#Requests > .wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px;
    gap: 30px;
}

#Requests > .wrapper > .Requests_Table_Container{
    height: 750px;
    width: 100%;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    background-color: white;
}

@media only screen and (max-width: 1024px) {
    #Requests > .wrapper {
        padding: 10px 15px;
    }
}


@media only screen and (max-width: 500px) {
    #Requests > .wrapper > .Monitoring_Table_Container{
        padding: 10px 20px;
    }
}