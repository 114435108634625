@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

:root{
  --light-grey:#D3D3D3;
  --orange:#FF8911;
  --dark-orange: #E2780E;
  --dirty-white:#F0EFF6;
  --shadow-white:#3A3535;
  --stroke: #313131;
  --divider: #d4d4d4;
  --pantone-black: #232020;
  --light-black: #3A3535;
  --bg-white:#F4F4F4;
  --bg2-white:#e7e7e7;
  --dark-grey: #A5A6A6;
  --green: #93C572;
  --light-green:#c8f7c8;
  --red: #FA5F55;
  --yellow: #FFC300;
  --light-yellow: #fffdd0;
  --blue: #4682B4;
  --light-blue: #759ab8;
  --error-red: #FF7F7F;
  --error-bg: #F2D2D4;
}

*,*::before,*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  overflow-x: hidden;
  background-color: #F4F4F4;
}


a {
    color: #084BD3;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 150ms ease-in-out;
}

button{
  outline: none;
}

button[disabled], button.disabled{
  background-color: var(--bg-white) !important;
  color: var(--dark-grey) !important;
  border: none !important;
  cursor: default !important;
}

a:hover {
    color: purple;
}

/* For WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(58, 53, 53, 0.5); /* Set the color of the scrollbar thumb */
  border-radius: 10px; /* Set the border radius of the scrollbar thumb */
}

#Main_View {
  display: flex;
  width: 100%;
  height: 100%;
}

#Main_View > .Sidebar_Overlay {
  animation: sidebarFadeIn 250ms ease-in-out forwards;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9;
  pointer-events: auto;
}

@keyframes sidebarFadeIn {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}

#Main_View > .Sidebar{
  display: block;
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 10;
}

/* App css */

#Main_View > .Main_Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#Main_View > .Main_Content > .Navbar {
  width: 100%;
}

#Main_View > .Main_Content > .Content {
  width: 100%;
}

#Main_View > .Main_Content > .Content.Sidebar {
  width: 100%;
  max-width: 1280px;
}

#Main_View > .Content.noScroll {
  overflow: hidden;
}

.swal2-container {
  z-index: 20000 !important;
}

.errorMessage{
  width: 100%;
  background-color: #F2D2D4;
  padding: 7px 14px;
  color: #C01F28;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}


.otpInput{
  width: 50px !important;
  height: 50px;
  margin-top: 4px;
  padding: 0 !important;
  text-align: center;
  font-size: 20px !important;
  border: 1px solid var(--light-black) !important;
  border-radius: 5px;
}

.otpInput:focus{
  border-color: var(--orange) !important;
}

/* CheckBox */
.checkbox-wrapper-46 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.checkbox-wrapper-46 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid var(--dark-grey);
  transition: all 0.2s ease;
}

.checkbox-wrapper-46 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--orange);
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}

.checkbox-wrapper-46 .cbx span:last-child {
  padding-left: 8px;
  color: var(--light-black);
  font-size: 15px;
}

.checkbox-wrapper-46 .cbx:hover span:first-child {
  border-color: var(--orange);
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
  background: var(--orange);
  border-color: var(--orange);
  animation: wave-46 0.4s ease;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
      transform: scale(0.9);
  }
}

.Input_Group{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.Input_Group > .Input_Label{
  font-size: 14px;
  color: var(--dark-grey);
}


/* Search Box */
.Input_Group > .Custom_Search{
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
  min-width: 200px;
  width: 100%;
}

.Input_Group > .Custom_Search > .Icon{
  position: relative;
  padding-left: 5px;
  color: var(--dark-grey);
  display: flex;
  align-items: center;
  z-index: 5;
}

.Input_Group > .Custom_Search > input{
  position: relative;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  border: none;
  outline: 1px solid var(--light-grey);
  background-color: white;
  padding: 5px 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  z-index: 4;
}

.Input_Group > .Custom_Search > input:focus-visible{
  outline: 1px solid var(--orange);
}

.Input_Group .Close_Icon{
  position: absolute;
  color: var(--light-black);
  display: flex;
  right: 0;
  padding-right: 5px;
  align-items: center;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
}

.Input_Group .Close_Icon.visible{
  opacity: 1;
}

/* Password  */
.Input_Group > .Custom_Password{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  position: relative;
  width: 100%;
}

.Input_Group > .Custom_Password > .Icon{
  padding-right: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--dark-grey);
  cursor: pointer;
  z-index: 99;
}

.Input_Group > .Custom_Password > input{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  border: none;
  outline: 1px solid var(--light-grey);
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 5px;
}

.Input_Group.staff > .Custom_Password > input{
  height: 40px;
  background-color: white;
}

.Input_Group > .Custom_Password > input:focus-visible{
  outline: 1px solid var(--orange);
}

.Input_Group > .Custom_Password.error > input{
  outline: 1px solid var(--red);
}

/* Email */
.Input_Group > .Custom_Email{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  position: relative;
  width: 100%;
}

.Input_Group > .Custom_Email > input{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  background-color: transparent;
  border: none;
  outline: 1px solid var(--light-grey);
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 5px;
}

.Input_Group > .Custom_Email > input:focus-visible{
  outline: 1px solid var(--orange);
}

.Input_Group > .Custom_Email.error > input{
  outline: 1px solid var(--red);
}


/* Filter MEnu */
.Filter_Container{
  padding: 10px 20px;
}

.Filter_Container{
  padding: 10px 20px;
}

.Filter_Container input::placeholder{
  color: var(--light-grey);
}

.Filter_Container > .Input_Group >  input{
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  size: 40px;
  border: none;
  outline: 1px solid var(--light-grey);
  border-radius: 5px;
}

.Filter_Container > .Input_Group > input:focus-visible, .Filter_Container > .Input_Group > select:focus-visible{
  outline: 1px solid var(--orange);
}


/* Scroll Up */
.Scroll_Top{
  display: flex;
  position: fixed;
  z-index: 100000;
  right: 30px;
  bottom: 30px;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.Scroll_Top.hide{
  display: none;
}

.Scroll_Top.visible{
  opacity: 1;
}

.Scroll_Top > span{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--light-black);
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  #Main_View > .Sidebar{
    position: fixed;
  }
}

@media only screen and (max-width: 1440px) {
  #Main_View > .Main_Content > .Content.Sidebar {
    width: 100%;
    max-width: 1080px;
  }
}
