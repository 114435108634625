

#System_Settings {
    display: flex;
    width: 100%;
}

#System_Settings > .wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px;
    gap: 30px;
}

#System_Settings > .wrapper > .Settings_Container{
    width: 100%;
    height: 700px;
    background-color: white;
    border-radius: 5px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    padding: 20px 30px;
}

#System_Settings > .wrapper > .Settings_Container .Tab_Panel{
    padding: 10px 0;
}

@media only screen and (max-width: 1024px) {
    #System_Settings > .wrapper{
        padding: 10px 15px;
    }
}

@media only screen and (max-width: 912px) {

}

@media only screen and (max-width: 540px) {
    #System_Settings > .wrapper > .Settings_Container{
        padding: 5px 10px;
    }
}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 320px) {

}