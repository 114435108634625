@media print{
    #Print_Container{
        width: 100%;
        height: 100%;
        font-family: Calibri, sans-serif !important;
        box-sizing: border-box;
        print-color-adjust: exact;
    }
    
    #Print_Container > .wrapper{
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    #Print_Container > .wrapper > .Header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 0;
    }

    #Print_Container > .wrapper > .Header > img{
        width: 100%;
    }
    
    #Print_Container > .wrapper > .Header > p{
        display: flex;
        position: absolute;
        top: 73px;
        left: 190px;
        font-weight: bold;
        font-size: 1.27rem;
        line-height: 1.9rem;
        width: 300px;
        color: #C00000;
    }

    #Print_Container > .wrapper > .Footer{
        position: fixed;
        bottom: 60px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 0;
    }

    #Print_Container > .wrapper > .Footer > p{
        text-align: center;
        margin: 0;
    }

    #Print_Container > .wrapper > .Footer > .Main{
        font-size: 10px;
        font-weight: bold;
    }

    #Print_Container > .wrapper > .Footer > .Sub{
        font-size: 8px;
    }

    #Print_Container > .wrapper > .Table_Container{
        width: 100%;
        position: relative;
        gap: 20px;
        z-index: 1;
    }

    #Print_Container > .wrapper > .Table_Container > table{
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }

    .break-row{
        page-break-after: always;
    }

    #Print_Container tr{
        print-color-adjust: exact;
    }

    #Print_Container tbody > tr:nth-child(odd){
        background-color: white;
    }

    #Print_Container tbody > tr:nth-child(even){
        background-color: whitesmoke;
    }

    #Print_Container th, #Print_Container td{
        width: 10%;
        word-wrap: break-word;
        padding: 3px 5px;
        border: 1px solid whitesmoke;
    }

    #Print_Container th{
        font-size: 12px;
        line-height: 1.5;
        font-weight: 300;
        background-color: var(--bg2-white);
        border: 1px solid var(--bg2-white);
    }

    #Print_Container td{
        color: var(--light-black);
        min-height: 50px;
        font-size: 12px;
    }

    

    #Print_Container thead > .spacer{
        height: 250px;
    }

    #Print_Container thead > .spacer > th{
        height: 250px;
        background-color: transparent;
        vertical-align: bottom;
        border: none;
    }

    #Print_Container thead > .spacer .Table_Title{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 30px;
    }

    #Print_Container thead > .spacer .Table_Title > p{
        font-size: 14px;
        color: var(--light-black);
        font-weight: bold;
    }
}

