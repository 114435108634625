#ViewPdf{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out forwards;
}

#ViewPdf > .ViewPdf_Container{
    width: 1000px;
    height: 100vh;
    padding-top: 20px;
    overflow-y: auto;
}

#ViewPdf > .Loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ViewPdf > .ViewPdf_Container::-webkit-scrollbar{
    display: none;
}

#ViewPdf > .ViewPdf_Container.landscape{
    width: 1300px;
}

#ViewPdf > .Close{
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 0;
    padding: 10px 20px;
}

#ViewPdf > .Close > .Icon{
    color: white;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#ViewPdf > .Close > .Icon:hover{
    color: var(--orange);
}


@media only screen and (max-width: 1600px) {

}

@media only screen and (max-width: 1024px) {
    #ViewPdf > .ViewPdf_Container{
        width: 700px;
    }
    #ViewPdf > .ViewPdf_Container.landscape{
        width: 900px;
    }
}

@media only screen and (max-width: 912px) {
    #ViewPdf > .ViewPdf_Container{
        width: 500px;
    }
}

@media only screen and (max-width: 540px) {
    
    
}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 320px) {

}