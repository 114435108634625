#Request_Dialog > .Dialog_Container{
    width: 100%;
    height: 100%;
    background-color: antiquewhite !important;
}

.required{
    color: var(--red);
}


.divider{
    content: '';
    width: 100%;
    border-top: 1px solid var(--dark-grey);
}

.divider.mobile{
    display: none;
}

.radio-input input {
    display: none;
}

.Radio_Container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-input {
    --container_width: 370px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #fff;
    color: #000000;
    width: var(--container_width);
    overflow: hidden;
    border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: 14px;
}

.selection {
    display: none;
    position: absolute;
    height: 100%;
    width: calc(var(--container_width) / 3);
    z-index: 0;
    left: 0;
    top: 0;
    transition: .15s ease;
}

.radio-input label:has(input:checked) {
    color: #fff;    
}

.radio-input label:has(input:checked) ~ .selection {
    background-color: var(--light-black);
    display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 0/3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 1/3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 2/3));
}

/* -------------------------------------------------------------------------------------------- */

/* Dialog Top */
.Dialog_Top{
    display: flex;
    justify-content: space-between;
}

.Dialog_Top > .Dialog_Title{
    font-size: 18px;
}

.Dialog_Top > .Dialog_Close{
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.Dialog_Top > .Dialog_Close:hover{
    color: var(--orange);
}
/* -------------------------------------------------------------------------------------------- */



/* Dialog Body */
.Inputs{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.Inputs > .Input_Group{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.Inputs .Options{
    display: none;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 100%;
    max-height: 150px;
    font-size: 14px;
    overflow-y: auto;
    background-color: white;
    outline: 1px solid var(--orange);
    border-radius: 5px;
    z-index: 999;
    transition: all 250ms ease-in-out;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
}

.Inputs .Options.show{
    display: flex;
}

.Inputs .Options > .Option{
    padding: 10px 20px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    width: 100%;
}

.Inputs .Options > .Option > p{
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Inputs .Options > .Option:hover{
    background-color: whitesmoke;
}

.Inputs .Input_Label{
    font-size: 14px;
    color: var(--light-black) !important;
}

.Inputs input, .Inputs select{
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    size: 40px;
    border: none;
    outline: 1px solid var(--light-grey);
    border-radius: 5px;
}

.Inputs input:focus-visible, .Inputs select:focus-visible{
    outline: 1px solid var(--orange);
}

.Inputs input::placeholder{
    color: var(--light-grey);
}

.Inputs .divider_group{
    display: flex;
    align-items: center;
    gap: 10px;
}

.Inputs .divider_group > span{
    font-size: 14px;
    color: var(--dark-grey);
}

.Inputs .Checkboxes{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.Inputs .Checkboxes > .Label{
    width: 100%;
    color: var(--light-black);
    font-size: 15px;
}

.Inputs .Input.error{
    outline: 1px solid var(--red);
}

/* -------------------------------------------------------------------------------------------- */




/* Dialog Actions */
.Dialog_Actions{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.Dialog_Cancel, .Dialog_Submit{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.Dialog_Submit{
    border: 1px solid var(--light-black);
    color: white;
    background-color: var(--light-black);
    transition: all 250ms ease-in-out;
}

.Dialog_Submit:hover{
    background-color: var(--orange);
    border-color: var(--orange);
}

.Dialog_Cancel{
    background: transparent;

    border: 1px solid var(--light-black);
    transition: all 250ms ease-in-out;
}

.Dialog_Cancel:hover{
    border-color: var(--dark-grey);
    color: white;
    background-color: var(--dark-grey);
}

/* For the Tracker */
.Inputs > .Signature_Container{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.Inputs > .Signature_Container > .Signature_Box{
    width: 100%;
    height: 90%;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--light-grey);
}

.Inputs > .Signature_Container > .Signature_Box.error{
    border-color: var(--red);
}

.Inputs > .Signature_Container > .Signature_Box > .Signature_Canvas {
    width: 100%;
    height: 100%;
}

.Inputs > .Signature_Container > .Signature_Clear{
    width: 100%;
    height: 10%;
    padding: 5px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    user-select: none;
}

.Inputs > .Signature_Container > .Signature_Clear:hover{
    color: var(--error-red);
}

.Inputs > .Signature_Container > .Signature_Label{
    font-size: 14px;
    color: var(--light-black);
}

.Dialog_Container .errorMessage{
    margin: 10px 0px;
}


/* Upload Files */

.Template_Upload{
    position: relative;
    width: 100%;
    height: 125px;
    background-color: var(--bg2-white);
    border: 2px dotted var(--dark-grey);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.Template_Upload > .Icon{
    color: var(--dark-grey);
}

.Template_Upload > .Main{
    font-size: 14px;
    color: var(--dark-grey);
}

.Template_Upload > .Sub{
    font-size: 12px;
    color: var(--dark-grey);
}

.Template_Files{
    width: 100%;
    height: 200px;
    margin-top: 20px;
    overflow-y: auto;
}

.Template_Files > .File{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.Template_Files > .File > .Icon{
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Template_Files > .File > .File_Label{
    width: 100%;
}

.Template_Files > .File > .File_Label > .Name{
    color: var(--light-black);
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.Template_Files > .File > .File_Label > .Size{
    color: var(--dark-grey);
    font-size: 12px;
}

.Template_Files > .File > .Remove{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

.Template_Files > .File > .Remove:hover{
    color: var(--red);
}

.Template_Upload > input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.RoleSelect_Container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
  

.RoleSelect_Container > .RoleSelect{
    width: 70%;
    padding: 10px 0px;
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--light-black);
    font-size: 14px;
    color: var(--light-black);
    transition: all 250ms ease-in-out;
}

.RoleSelect_Container > .RoleSelect:focus-visible{
    border-color: var(--orange);
}

.RoleSelect_Container > .RoleSelect > .roleOption{
    padding: 10px 0px;
}

/* -------------------------------------------------------------------------------------------- */
@media only screen and (max-width: 1600px) {
    .Dialog_Body > .wrapper > .Left_Side{
        height: 490px;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files {
        height: 150px;
    }
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 912px) {
    .Dialog_Body > .wrapper > .Left_Side{
        height: 600px;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files {
        height: 250px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File{
        width: 80%;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size {
        width: 100%;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size > .Name{
        width: 200px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Icon {
        width: 35px !important;
    }
}

@media only screen and (max-width: 540px) {
    .Dialog_Body > .wrapper {
        flex-direction: column;
    }

    .Dialog_Body > .wrapper > .Left_Side{
        width: 100%;
    }

    .Dialog_Body > .wrapper > .Right_Side{
        width: 100%;
    }

    .divider.mobile{
        display: block;
    }

    .radio-input {
        --container_width: 310px;
    }

    .radio-input span{
        text-align: center;
    }
}

@media only screen and (max-width: 390px) {
    .Dialog_Body > .wrapper > .Left_Side > .Date_Time{
        flex-direction: column;
    }

    .radio-input {
        --container_width: 250px;
    }

    .radio-input span{
        text-align: center;
    }
}

@media only screen and (max-width: 320px) {
    .Dialog_Body > .wrapper > .Left_Side{
        height: 600px;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files {
        height: 250px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File{
        width: 75%;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size {
        width: 100%;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size > .Name{
        width: 150px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Icon {
        width: 35px !important;
    }

    .radio-input {
        --container_width: 200px;
    }

    .radio-input span{
        text-align: center;
        font-size: 12px;
    }
}