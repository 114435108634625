#Staff_Onboarding{
    width: 100vw;
    height: 100vh;
}

#Staff_Onboarding > .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#Staff_Onboarding > .wrapper > .Stepper_Holder{
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Staff_Onboarding > .wrapper > .Stepper_Holder > .Stepper{
    width: 100%;
}

#Staff_Onboarding > .wrapper > .Stepper_Holder > .Stepper text{
    fill: white;
}


#Staff_Onboarding > .wrapper > .Stepper_Holder > .Stepper .MuiStepLabel-label {
    font-size: 12px;
    color: var(--light-black);
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container{
    width: 500px;
    height: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    padding: 30px 30px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Back_Icon{
    width: 100%;
    color: var(--light-black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Back_Icon:hover{
    color: var(--orange);
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Logos{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Logos > img{
    width: 50px;
    height: 50px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Main_Label > p{
    color: var(--light-black);
    font-size: 20px;
    font-weight: bold;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Sub_Label > p{
    color: var(--dark-grey);
    font-size: 13px;
    text-align: center;
    word-wrap: break-word;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .Name_Group{
    display: flex;
    align-items: center;
    gap: 10px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > div{
    width: 100%;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Button{
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Button > button{
    width: 100%;
    padding: 10px;
    border:  none;
    background-color: var(--light-black);
    color: white;
    border-radius: 5px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Button > button:hover{
    background-color: var(--orange);
}
#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Button > .Cancel{
    width: 100%;
    padding: 10px;
    border:  none;
    background-color: transparent;
    color: var(--light-black);
    border: 1px solid var(--light-black);
    border-radius: 5px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Button > .Cancel:hover{
    background-color: var(--dark-grey);
    border-color: var(--dark-grey);
    color: white;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .Label{
    color: var(--light-black);
    font-size: 16px;
    display: flex;
    align-items: center;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .OtpInput_Container{
    width: 350px !important; 
    display: flex;
    justify-content: space-between;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .OtpInput_Container > .OtpInput_Input{
    width: 40px !important;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: 1px solid var(--dark-grey);
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .OtpInput_Container > .OtpInput_Input:focus-visible{
    outline: 1px solid var(--orange);
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .Resend{
    color: var(--light-black);
    font-size: 15px;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .Resend > span{
    color: var(--orange);
    cursor: pointer;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Forgot_Password_Success_Icon{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Forgot_Password_Success_Icon > img{
    width: 250px;
    height: 250px;
}


@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 912px) {

}

@media only screen and (max-width: 540px) {

    #Staff_Onboarding > .wrapper{
        justify-content: flex-start;
        margin-top: 25px;
    }

    #Staff_Onboarding > .wrapper > .Stepper_Holder{
        width: 400px;
    }

    #Staff_Onboarding > .wrapper > .Stepper_Holder > .Stepper .MuiStepLabel-label {
        font-size: 10px;
    }
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container{
        width: 400px;
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Main_Label > p{
        font-size: 18px;
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Sub_Label > p{
        color: var(--dark-grey);
        font-size: 12px;
        text-align: center;
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 390px) {
    #Staff_Onboarding > .wrapper > .Stepper_Holder{
        width: 350px;
    }

    #Staff_Onboarding > .wrapper > .Stepper_Holder > .Stepper .MuiStepLabel-label {
        font-size: 10px;
    }
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container{
        width: 350px;
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Main_Label > p{
        font-size: 15px;
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Sub_Label > p{
        color: var(--dark-grey);
        font-size: 11px;
        text-align: center;
        word-wrap: break-word;
    }

    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .OtpInput_Container{
        width: 300px !important; 
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .OtpInput_Container > .OtpInput_Input{
        width: 40px !important;
        height: 40px;
        border-radius: 5px;
        border: none;
        outline: 1px solid var(--dark-grey);
    }
}

@media only screen and (max-width: 320px) {
    #Staff_Onboarding > .wrapper > .Stepper_Holder{
        width: 300px;
    }

    #Staff_Onboarding > .wrapper > .Stepper_Holder > .Stepper .MuiStepLabel-label {
        font-size: 10px;
    }
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container{
        width: 300px;
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Main_Label > p{
        font-size: 12px;
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Label > .Sub_Label > p{
        color: var(--dark-grey);
        font-size: 11px;
        text-align: center;
        word-wrap: break-word;
    }

    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .OtpInput_Container{
        width: 250px !important; 
    }
    
    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .OtpInput_Container > .OtpInput_Input{
        width: 35px !important;
        height: 35px;
        border-radius: 5px;
        border: none;
        outline: 1px solid var(--dark-grey);
    }

    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .Resend{
        color: var(--light-black);
        font-size: 12px;
    }

    #Staff_Onboarding > .wrapper > .Forgot_Password_Container > .Forgot_Password_Content > .Forgot_Password_Content_Inner > .Forgot_Password_Input > .Label{
        color: var(--light-black);
        font-size: 13px;
        display: flex;
        align-items: center;
    }
}