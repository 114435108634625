
#Archive_Table {
    display: flex;
    width: 100%;
}

#Archive_Table > .wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px;
    gap: 30px;
}

#Archive_Table > .wrapper > .GoBack{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#Archive_Table > .wrapper > .GoBack > span{
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#Archive_Table > .wrapper > .GoBack > span:hover {
    color: var(--orange);
    text-decoration: underline 2px solid var(--orange);
}

#Archive_Table > .wrapper > .Archive_Table_Container{
    height: 750px;
    width: 100%;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    background-color: white;
}

@media only screen and (max-width: 1024px) {
    #Archive_Table > .wrapper {
        padding: 10px 15px;
    }
}


@media only screen and (max-width: 500px) {
    #Archive_Table > .wrapper > .Monitoring_Table_Container{
        padding: 10px 20px;
    }
}