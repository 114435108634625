::-webkit-calendar-picker-indicator{
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#System_Logs {
    width: 100%;
    height: 100%;
}

#System_Logs > .wrapper{
    width: 100%;
    height: 100%;
    padding: 10px 20px;
}

#System_Logs > .wrapper > .Top_Side{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    gap: 20px;
}

#System_Logs > .wrapper > .Top_Side > .Input_Group{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

#System_Logs > .wrapper > .Top_Side > .Input_Group > .Input_Label{
    font-size: 14px;
    color: var(--dark-grey);
}

#System_Logs > .wrapper > .Top_Side > .Input_Group > input, #System_Logs > .wrapper > .Top_Side > .Input_Group > select{
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    size: 40px;
    border: none;
    outline: 1px solid var(--light-grey);
    border-radius: 5px;
}

#System_Logs > .wrapper > .Top_Side > .Input_Group input:focus-visible, #System_Logs > .wrapper > .Top_Side > .Input_Group select:focus-visible{
    outline: 1px solid var(--orange);
}

#System_Logs > .wrapper > .Top_Side > .Input_Group > .Checkboxes{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#System_Logs > .wrapper > .Top_Side > .Input_Group > .Checkboxes > .Label{
    color: var(--dark-grey);
    font-size: 14px;
}

#System_Logs > .wrapper > .Top_Side > .Input_Group > .Checkboxes > .CheckBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    gap: 10px;
}

#System_Logs > .wrapper > .System_Logs_Container{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

#System_Logs > .wrapper > .System_Logs_Container > .Table_Empty{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Image{
    text-align: center;
}

#System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Image > img{
    width: 300px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels > span{
    text-align: center;
}

#System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels > .Main_Label{
    font-weight: bold;
    color: var(--light-black);
    font-size: 18px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels > .Sub_Label{
    color: var(--dark-grey);
    font-size: 14px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs{
    width: 100%;
    height: 500px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log:nth-child(odd){
    background-color: var(--bg-white);
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Icon{
    color: var(gray);
    display: flex;
    align-items: center;
    transition: all 250ms ease-in-out;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log:hover > .Icon{
    color: var(--orange);
    transform: rotate(45deg);
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text {
    width: 100%;
    display: flex;
    align-items: center;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .DateTime{
    width: 25%;
    color: gray;
    display: flex;
    align-items: center;
    font-size: 14px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .DateTime > p{
    display: flex;
    align-items: center;
    gap: 5px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .DateTime .dot{
    font-size: 20px;
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event{
    display: flex;
    align-items: center;
    font-size: 15px;
    width: 75%;
    color: var(--light-black);
}

#System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event > p{
    width: 800px;
    word-wrap: break-word;
}

@media only screen and (max-width: 1440px) {
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event > p{
        width: 630px;
    }

    .checkbox-wrapper-46 .cbx span:last-child{
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 1024px) {
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .DateTime{
        width: 30%;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event{
        width: 70%;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event > p{
        width: 550px;
    }

    .checkbox-wrapper-46 .cbx span:last-child{
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 912px) {
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text{
        flex-direction: column;
        align-items: start;
    }
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .DateTime{
        width: 100%;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event{
        width: 100%;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event > p{
        width: 550px;

    }
}

@media only screen and (max-width: 540px) {
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event > p{
        width: 230px;
    }
    #System_Logs > .wrapper > .Top_Side{
        flex-direction: column;
        height: 230px;
    }
    #System_Logs > .wrapper > .System_Logs_Container > .Logs{
        height: 350px;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Image > img{
        width: 250px;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels > .Main_Label{
        font-size: 16px;
    }
    
    #System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels > .Sub_Label{
        font-size: 12px;
    }
}

@media only screen and (max-width: 390px) {
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event > p{
        width: 200px;
    }
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .DateTime{
        font-size: 12px;
    }

    .checkbox-wrapper-46 .cbx span:last-child{
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 320px) {
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .Event > p{
        width: 130px;
    }
    #System_Logs > .wrapper > .System_Logs_Container > .Logs > .Log > .Text > .DateTime{
        font-size: 12px;
    }
    .checkbox-wrapper-46 .cbx span:last-child{
        font-size: 11px !important;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Image > img{
        width: 200px;
    }

    #System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels > .Main_Label{
        font-size: 14px;
    }
    
    #System_Logs > .wrapper > .System_Logs_Container > .Table_Empty > .Empty_Labels > .Sub_Label{
        font-size: 10px;
    }
}