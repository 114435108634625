#Setting_Accounts{
    width: 100%;
    height: 100%;
}

.User_Options{
    width: 100%;
    height: 100%;
    padding: 10px 16px;
    display: flex;
    color: var(--light-black);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

.User_Options > p{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.User_Options > p > span{
    color: var(--dark-grey);
    transition: all 250ms ease-in-out;
}

.User_Options.Active:hover{
    background-color: #F2D2D4;
    color: #C01F28;
}

.User_Options.Active:hover > p > span{
    color: #C01F28;
}

.User_Options.Deactivated:hover{
    background-color: #d2f2d7;
    color: #029e0f;
}

.User_Options.Active:hover > p > span{
    color: #C01F28;
}

.User_Options.Deactivated:hover > p > span{
    color: #029e0f;
}

#Setting_Accounts > .wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#Setting_Accounts > .wrapper > .Accounts_Top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Left{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Left > *{
    display: flex;
    justify-content: center;
    align-items: center;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Left > button{
    height: 40px;
    padding: 10px 20px;
    border: none;
    background-color: var(--light-black);
    color: white;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0 4px 2px -2px rgba(187, 187, 187, 0.5);
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Left > button:hover{
    background-color: var(--dark-orange);
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Left > span{
    color: var(--dark-grey);
    transition: all 250ms ease-in-out;
    user-select: none;
    cursor: pointer;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right{
    display: flex;
    align-items: center;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 10px;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group{
    width: 150px;
    display: flex;
    align-items: center;
    color: var(--dark-grey);
    cursor: pointer;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group > .Label{
    font-size: 14px;
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group:hover{
    transition: color 250ms ease-in-out;
    color: var(--light-black);
}

#Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group.active{
    color: var(--orange);
}

#Setting_Accounts > .wrapper > .Table_Empty{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

#Setting_Accounts > .wrapper > .Table_Empty > .Empty_Image{
    text-align: center;
}

#Setting_Accounts > .wrapper > .Table_Empty > .Empty_Image > img{
    width: 300px;
}

#Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

#Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels > span{
    text-align: center;
}

#Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels > .Main_Label{
    font-weight: bold;
    color: var(--light-black);
    font-size: 18px;
}

#Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels > .Sub_Label{
    color: var(--dark-grey);
    font-size: 14px;
}

#Setting_Accounts > .wrapper > .Accounts_List{
    max-height: 550px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 20px;
    gap: 10px;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account{
    display: flex;
    align-items: center;
    background-color: var(--bg2-white);
    padding: 10px 10px;
    border-radius: 5px;
    height: 100px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    gap: 10px;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Profile_Pic{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Profile_Pic > .Profile_Pic{
    width: 75px;
    height: 75px;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status{
    width: 220px;
    height: 100%;
    display: flex;
    padding: 5px 0;
    flex-direction: column;
    justify-content: space-between;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Name{
    color: var(--light-black);
    font-size: 15px;
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Email{
    color: var(--dark-grey);
    font-size: 13px;
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Status > p{
    font-size: 14px;
    color: var(--green);
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Status.Active > p{
    color: var(--green);
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Status.Deactivated > p{
    color: var(--red);
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Status.Pending > p{
    color: var(--yellow);
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Status.Temporary > p{
    color: var(--blue);
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Options{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Options .Status_Icon{
    color: var(--light-black);
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Options .Status_Icon:hover{
    color: var(--orange);
}

@media only screen and (max-width: 1440px) {
    #Setting_Accounts > .wrapper > .Accounts_List{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 912px) {
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Profile_Pic > .Profile_Pic{
        width: 50px;
        height: 50px;
    }

    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status{
        width: 180px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Name{
        width: 190px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Email{
        width: 190px;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Left{
        width: 100%;
        justify-content: flex-start;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_Top{
        gap: 10px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group{
        width: 100px;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters{
        gap: 5px;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group > .Label{
        font-size: 14px;
    }
}

@media only screen and (max-width: 540px) {
    #Setting_Accounts > .wrapper > .Accounts_Top{
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group{
        width: 100px;
        flex-direction: column;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right{
        flex-direction: column;
        gap: 10px;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > *{
        width: 100%;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Left > button{
        width: 100%;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .table-input{
        width: 100%;
    }

    #Setting_Accounts > .wrapper > .Accounts_List{
        grid-template-columns: 1fr;
        max-height: 520px;
    }

    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status{
        padding: 0;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group > .Label{
        text-align: center;
    }
}

@media only screen and (max-width: 414px) {
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status{
        width: 130px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Name{
        width: 140px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Email{
        width: 140px;
    }

    #Setting_Accounts > .wrapper > .Table_Empty > .Empty_Image > img{
        width: 250px;
    }
    
    #Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels > .Main_Label{
        font-size: 16px;
    }
    
    #Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels > .Sub_Label{
        font-size: 12px;
    }
}

@media only screen and (max-width: 360px) {
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status{
        width: 120px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Name{
        width: 130px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Email{
        width: 130px;
    }
}

@media only screen and (max-width: 320px) {
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status{
        width: 80px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Name{
        width: 90px;
    }
    
    #Setting_Accounts > .wrapper > .Accounts_List > .Account > .Account_Email_Name_Status > .Email_Name > .Email{
        width: 90px;
    }

    #Setting_Accounts > .wrapper > .Accounts_Top > .Accounts_Top_Right > .Filters > .Filter_Group > .Label{
        font-size: 12px;
    }

    #Setting_Accounts > .wrapper > .Table_Empty > .Empty_Image > img{
        width: 200px;
    }
    
    #Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels > .Main_Label{
        font-size: 14px;
    }
    
    #Setting_Accounts > .wrapper > .Table_Empty > .Empty_Labels > .Sub_Label{
        font-size: 10px;
    }
}