#Comm_Add_Dialog > .Dialog_Container{
    width: 100%;
    height: 100%;
    background-color: antiquewhite !important;
}

.required{
    color: var(--red);
}


.divider{
    content: '';
    width: 100%;
    border-top: 1px solid var(--dark-grey);
}

.divider.mobile{
    display: none;
}

/* CheckBox */
.checkbox-wrapper-8 .tgl {
    display: none;
}

.checkbox-wrapper-8 .tgl,
.checkbox-wrapper-8 .tgl:after,
.checkbox-wrapper-8 .tgl:before,
.checkbox-wrapper-8 .tgl *,
.checkbox-wrapper-8 .tgl *:after,
.checkbox-wrapper-8 .tgl *:before,
.checkbox-wrapper-8 .tgl + .tgl-btn {
    box-sizing: border-box;
}

.checkbox-wrapper-8 .tgl::-moz-selection,
.checkbox-wrapper-8 .tgl:after::-moz-selection,
.checkbox-wrapper-8 .tgl:before::-moz-selection,
.checkbox-wrapper-8 .tgl *::-moz-selection,
.checkbox-wrapper-8 .tgl *:after::-moz-selection,
.checkbox-wrapper-8 .tgl *:before::-moz-selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-8 .tgl::selection,
.checkbox-wrapper-8 .tgl:after::selection,
.checkbox-wrapper-8 .tgl:before::selection,
.checkbox-wrapper-8 .tgl *::selection,
.checkbox-wrapper-8 .tgl *:after::selection,
.checkbox-wrapper-8 .tgl *:before::selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::selection {
    background: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 25px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after, .checkbox-wrapper-8 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after {
    left: 0;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:before {
    display: none;
}

.checkbox-wrapper-8 .tgl:checked + .tgl-btn:after {
    left: 50%;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.2s ease;
    font-family: sans-serif;
    background: var(--dark-grey);
}
.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after, .checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
    transform: skew(10deg);
    display: inline-block;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 25px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active {
    background: #888;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active:before {
    left: -10%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn {
    background: var(--orange);
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:before {
    left: -100%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:after {
    left: 0;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%;
}
/* -------------------------------------------------------------------------------------------- */

/* Dialog Top */
.Dialog_Top{
    display: flex;
    justify-content: space-between;
}

.Dialog_Top > .Dialog_Title{
    font-size: 18px;
    width: 700px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Dialog_Top > .Dialog_Close{
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.Dialog_Top > .Dialog_Close:hover{
    color: var(--orange);
}
/* -------------------------------------------------------------------------------------------- */



/* Dialog Body */
.Dialog_Body > .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 990;
    display: flex;
}

::-webkit-calendar-picker-indicator{
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.Dialog_Body > .wrapper > .Left_Side{
    width: 50%;
    height: 700px;
    overflow-y: auto;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Dialog_Body > .wrapper > .Left_Side .Input_Group{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}



.Dialog_Body > .wrapper > .Left_Side .Input_Group > .Options{
    display: none;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 100%;
    max-height: 200px;
    font-size: 14px;
    overflow-y: auto;
    background-color: white;
    outline: 1px solid var(--orange);
    border-radius: 5px;
    z-index: 999;
    transition: all 250ms ease-in-out;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
}

.Dialog_Body > .wrapper > .Left_Side :nth-last-child(-n+2) > .Options{
    top: auto;
    bottom: 70%;
}

.Dialog_Body > .wrapper > .Left_Side .Input_Group > .Options.show{
    display: flex;
}

.Dialog_Body > .wrapper > .Left_Side .Input_Group > .Options > .Option{
    padding: 10px 20px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.Dialog_Body > .wrapper > .Left_Side .Input_Group > .Options > .Option > p{
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Dialog_Body > .wrapper > .Left_Side .Input_Group > .Options > .Option:hover{
    background-color: whitesmoke;
}

.Dialog_Body > .wrapper > .Left_Side > .Date_Time{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.Dialog_Body > .wrapper > .Left_Side .Input_Label{
    font-size: 14px;
    color: var(--light-black);
}

.Dialog_Body > .wrapper > .Left_Side input::placeholder{
    color: var(--light-grey);
}

.Dialog_Body > .wrapper > .Left_Side input, .Dialog_Body > .wrapper > .Left_Side select{
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    size: 40px;
    border: none;
    outline: 1px solid var(--light-grey);
    border-radius: 5px;
}

.Dialog_Body > .wrapper > .Left_Side input:focus-visible, .Dialog_Body > .wrapper > .Left_Side select:focus-visible{
    outline: 1px solid var(--orange);
}

.Dialog_Body > .wrapper > .Right_Side{
    width: 50%;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
}

.Dialog_Body > .wrapper > .Right_Side > .Label{
    font-size: 14px;
    color: var(--dark-grey);
}

.Dialog_Body > .wrapper > .Right_Side > .FileUpload{
    width: 100%;
    height:150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 3px dotted var(--dark-grey);
    border-radius: 5px;
    gap: 5px;
    
}

.Dialog_Body > .wrapper > .Right_Side > .FileUpload > .Icon {
    color: var(--dark-grey);
}

.Dialog_Body > .wrapper > .Right_Side > .FileUpload > p {
    color: var(--dark-grey);
}

.Dialog_Body > .wrapper > .Right_Side > .FileUpload > .Main{
    font-size: 16px;
}

.Dialog_Body > .wrapper > .Right_Side > .FileUpload > .Sub{
    font-size: 12px;
}

.Dialog_Body > .wrapper > .Right_Side > .FileUpload > input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.Dialog_Body > .wrapper > .Right_Side > .Files {
    width: 100%;
    height: 300px;
    padding: 10px 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Dialog_Body > .wrapper > .Right_Side > .Files > .File{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Icon {
    height: 100%;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size > .Name{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: bold;
    color: var(--light-black);
}

.Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size > .Size{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: var(--dark-grey);
}

.Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Remove > .Close_Icon{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-black);
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Remove > .Close_Icon:hover{
    color: var(--red);
}

.Dialog_Body > .wrapper > .Right_Side > .Urgent_Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Dialog_Body > .wrapper > .Right_Side > .Urgent_Container > .Label{
    color: var(--dark-grey);
    font-size: 14px;
}

/* -------------------------------------------------------------------------------------------- */




/* Dialog Actions */
.Dialog_Actions{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.Dialog_Cancel, .Dialog_Submit{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.Dialog_Submit{
    border: 1px solid var(--light-black);
    color: white;
    background-color: var(--light-black);
    transition: all 250ms ease-in-out;
}

.Dialog_Submit:hover{
    background-color: var(--orange);
    border-color: var(--orange);
}

.Dialog_Cancel{
    background: transparent;

    border: 1px solid var(--light-black);
    transition: all 250ms ease-in-out;
}

.Dialog_Cancel:hover{
    border-color: var(--dark-grey);
    color: white;
    background-color: var(--dark-grey);
}
/* -------------------------------------------------------------------------------------------- */
@media only screen and (max-width: 1600px) {
    .Dialog_Body > .wrapper > .Left_Side{
        height: 490px;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files {
        height: 150px;
    }
}

@media only screen and (max-width: 1024px) {
    .Dialog_Top > .Dialog_Title{
        width: 700px;
    }
}

@media only screen and (max-width: 912px) {
    .Dialog_Body > .wrapper > .Left_Side{
        height: 600px;
    }

    .Dialog_Top > .Dialog_Title{
        width: 600px;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files {
        height: 250px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File{
        width: 80%;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size {
        width: 100%;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size > .Name{
        width: 200px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Icon {
        width: 35px !important;
    }
}

@media only screen and (max-width: 540px) {
    .Dialog_Body > .wrapper {
        flex-direction: column;
    }

    .Dialog_Top > .Dialog_Title{
        width: 300px;
    }

    .Dialog_Body > .wrapper > .Left_Side{
        width: 100%;
    }

    .Dialog_Body > .wrapper > .Right_Side{
        width: 100%;
    }

    .divider.mobile{
        display: block;
    }
}

@media only screen and (max-width: 390px) {
    .Dialog_Body > .wrapper > .Left_Side{
        padding: 10px 16px;
    }
    .Dialog_Body > .wrapper > .Right_Side{
        padding: 10px 16px;
    }
    .Dialog_Body > .wrapper > .Left_Side > .Date_Time{
        flex-direction: column;
    }

    .Dialog_Top > .Dialog_Title{
        width: 250px;
    }
}

@media only screen and (max-width: 320px) {
    .Dialog_Body > .wrapper > .Left_Side{
        height: 600px;
    }

    .Dialog_Top > .Dialog_Title{
        width: 200px;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files {
        height: 250px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File{
        width: 75%;
    }

    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size {
        width: 100%;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Name_Size > .Name{
        width: 150px;
    }
    .Dialog_Body > .wrapper > .Right_Side > .Files > .File > .Icon {
        width: 35px !important;
    }
}