#View_Files > .wrapper{
    width: 100%;
    height: 100%;
}

.Loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#View_Files > .wrapper .Tab_Panel{
    height: 700px;
    overflow-y: auto;
}

#View_Files > .wrapper .Tab_Panel .Image_Download{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50px;
}

#View_Files > .wrapper .Tab_Panel .Image_Download > button{
    height: 40px;
    background-color: var(--light-black);
    color: white;
    padding-inline: 10px;
    border: none;
    border-radius: 5px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

#View_Files > .wrapper .Tab_Panel .Image_Download > button:hover{
    background-color: var(--orange);
}

#View_Files > .wrapper .Tab_Panel .Image_Item_Holder{
    overflow: hidden;
}

#View_Files > .wrapper .Tab_Panel .Image_Item{
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#View_Files > .wrapper .Tab_Panel .Image_Item:hover{
    opacity: 0.6;
    transform: scale(1.1);
}

#View_Files .Files_Container{
    width: 100%;
    height: 650px;
    overflow-y: auto;
}

#View_Files .Files_Container > .FileList{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px 20px;
    gap: 20px;
}

#View_Files .Files_Container > .FileList > .File{
    background-color: white;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 5px 10px;
    gap: 10px;
}

#View_Files .Files_Container > .FileList > .File > .Icon{
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
}

#View_Files .Files_Container > .FileList > .File > .Name{
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#View_Files .Files_Container > .FileList > .File > .Name > p{
    width: 150px;
    font-size: 15px;
    color: var(--light-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#View_Files .Files_Container > .FileList > .File > .Actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
    gap: 10px;
    color: var(--dark-grey);
}

#View_Files .Files_Container > .FileList > .File > .Actions > *{
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#View_Files .Files_Container > .FileList > .File > .Actions > *:hover{
    color: var(--light-black);
}


@media only screen and (max-width: 1024px) {
    #View_Files .Files_Container > .FileList{
        grid-template-columns: 1fr 1fr;
    }

    #View_Files .Files_Container > .FileList > .File > .Name > p{
        width: 118px;
    }
}

@media only screen and (max-width: 912px) {
    #View_Files .Files_Container > .FileList{
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 390px) {
    #View_Files .Files_Container > .FileList > .File > .Name > p{
        width: 120px;
    }
}

@media only screen and (max-width: 320px) {
    #View_Files .Files_Container > .FileList > .File > .Name > p{
        width: 100px;
    }
}