

#Dashboard {
    display: flex;
    width: 100%;
}

#Dashboard > .wrapper{
    width: 100%;
    padding: 20px 30px;
}

#Dashboard > .wrapper > .Dashboard_Grids{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.Dashboard_Grid_Card.Welcome{ grid-area: welcome; }
.Dashboard_Grid_Card.Archive{ grid-area: archive; }
.Dashboard_Grid_Card.DocToday{ grid-area: today; }
.Dashboard_Grid_Card.DocMonth{ grid-area: month; }
.Dashboard_Grid_Card.DocYear{ grid-area: year; }
.Dashboard_Grid_Card.Graph1{ grid-area: graph1; }
.Dashboard_Grid_Card.Graph2{ grid-area: graph2; }
.Dashboard_Grid_Card.Graph3{ grid-area: graph3; }
.Dashboard_Grid_Card.Graph4{ grid-area: graph4; }
.Dashboard_Grid_Card.Box1{ grid-area: box1; }
.Dashboard_Grid_Card.Box2{ grid-area: box2; }
.Dashboard_Grid_Card.Box3{ grid-area: box3; }

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container {
    margin: 5px 0 20px 0;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 
    'welcome archive today month year';
    gap: 10px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome{
    display: flex;
    align-items: center;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > img{
    width: 100px;
    height: 100%;
    padding-top: 7px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin-left: 20px;
    max-width: 250px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg > .Welcome{
    color: #F4F4F4;
    font-size: 25px;
    font-weight: bold;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg > .Welcome > span{
    color: var(--orange);
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg > .Name {
    color: var(--light-grey);
    font-size: 22px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Middle{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    'graph1 graph2'
    'graph3 graph4';
    gap: 10px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Middle.Faculty{
    grid-template-areas: 
    'graph1 graph4'
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card{
    height: 125px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: white;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
}


#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card > .Card_Icon{
    display: flex;
    align-items: center;
    font-size: 50px;
    color: white;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card > .Card_Label{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card > .Card_Label > span{
    color: var(--shadow-white);
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card > .Card_Label > .span1{
    font-size: 15px;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card > .Card_Label > .span2{
    font-size: 15px;
    font-weight: bold;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card > .Card_Label > p{
    font-size: 25px;
    font-family: "Oswald";
    color: var(--light-black);
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome{
    background-color: var(--pantone-black);
}

.Dashboard_Grid_Card.Archive, .Dashboard_Grid_Card.DocToday, .Dashboard_Grid_Card.DocMonth, .Dashboard_Grid_Card.DocYear{
    background-color: var(--light-grey) !important;
    transition: all 250ms ease-in-out;
}

.Dashboard_Grid_Card.Archive:hover, .Dashboard_Grid_Card.DocToday:hover, .Dashboard_Grid_Card.DocMonth:hover, .Dashboard_Grid_Card.DocYear:hover{
    background-color: #e1e1e1 !important;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Middle > .Dashboard_Grid_Card{
    height: 400px;
    padding: 10px;
    border-radius: 5px;
    display: block;
    background-color: white;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    transition: all 250ms ease-in-out;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Middle > .Dashboard_Grid_Card:not(.Calendar):hover{
    background-color: whitesmoke;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Middle > .Dashboard_Grid_Card > .Chart_Wrapper{
    height: 100%;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Middle > .Dashboard_Grid_Card > .Chart_Wrapper > .Calendar{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'box1 box2 box3';
    gap: 10px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card{
    height: 300px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    color: var(--light-black);
    transition: all 250ms ease-in-out;
    border-top: 3px solid var(--orange);
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card:hover{
    background-color: whitesmoke;
    border-color: var(--dark-orange);
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Title{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 2px solid grey;
    padding: 5px 0;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Title > span{
    color: orange;
    font-weight: bold;
    font-size: 18px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Title > .Labels{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;
    font-size: 15px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    height: 200px;
    overflow-y: auto;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content > .Content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 250ms ease-in-out;
    cursor: default;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content > .Content > .Name > p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
}

#Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content > .Content:hover{
    color: grey;
}


#Dashboard > .wrapper  > .Dashboard_Table_Container{
    display: block;
    background-color: white;
    height: 750px;
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
}

#Dashboard > .wrapper > .Dashboard_Table_Container > .Dashboard_Table {
    width: 100%;
    height: 100%;
}


/* Skeleton Loading */

#Dashboard > .wrapper.loading > .Dashboard_Grids,
#Dashboard > .wrapper.loading > .Dashboard_Table_Container{
    animation: loading-animation 2s ease-in-out infinite;
}

#Dashboard > .wrapper.loading > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card,
#Dashboard > .wrapper.loading > .Dashboard_Grids > .Dashboard_Grid_Container.Middle > *,
#Dashboard > .wrapper.loading > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > *,
#Dashboard > .wrapper.loading > .Dashboard_Table_Container{
    border: none;
    background-color: rgb(216, 216, 216) !important;
}

#Dashboard > .wrapper.loading > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card:hover,
#Dashboard > .wrapper.loading > .Dashboard_Grids > .Dashboard_Grid_Container.Middle > *:hover,
#Dashboard > .wrapper.loading > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > *:hover{
    border: none;
    background-color: rgb(216, 216, 216) !important;
}


@keyframes loading-animation {
    0% {
      opacity: 0.7; /* Initial opacity */
    }
    50% {
      opacity: 1; /* Peak opacity */
    }
    100% {
      opacity: 0.7; /* Final opacity */
    }
  }

@media only screen and (max-width: 1024px) {
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top{
        grid-template-columns: 2fr 2fr 2fr;
        grid-template-areas: 
        'welcome welcome archive'
        'today month year';
    }

    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content > .Content > .Name > p{
        width: 200px;
    }
}

@media only screen and (max-width: 820px) {
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top{
        grid-template-areas: 
        'welcome welcome archive'
        ' today month year';
    }

    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Middle{
        grid-template-columns: 1fr;
        grid-template-areas: 
        'graph1'
        'graph2'
        'graph3'
        'graph4';
    }
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content > .Content > .Name > p{
        width: 150px;
    }
}

@media only screen and (max-width: 430px) {
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top{
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        'welcome welcome'
        'archive today'
        'month year';
    }
    #Dashboard > .wrapper{
        padding: 20px 15px;
    }
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
        'box1'
        'box2'
        'box3';
        gap: 10px;
    }
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content > .Content > .Name > p{
        width: 250px;
    }

    #Dashboard > .wrapper  > .Dashboard_Table_Container{
        height: 650px;
    }
}

@media only screen and (max-width: 420px) {
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card{
        height: 100px;
    }
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top{
        grid-template-columns: 1fr;
        grid-template-areas: 
        'welcome'
        'archive'
        'today'
        'month'
        'year';
    }

    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > img{
        width: 90px;
        height: 90px;
    }
    
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg > .Welcome{
        font-size: 22px;
    }
    
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg > .Name {
        font-size: 20px;
    }

    #Dashboard > .wrapper  > .Dashboard_Grids > .Dashboard_Grid_Container.Middle > .Dashboard_Grid_Card > .Chart_Wrapper{
        padding: 0;
    }
    
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Bottom > .Dashboard_Grid_Card > .Box_Wrapper > .Box_Content > .Content > .Name > p{
        width: 200px;
    }
}

@media only screen and (max-width: 340px){
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > img{
        width: 85px;
        height: 85px;
    }
    
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg > .Welcome{
        font-size: 20px;
    }
    
    #Dashboard > .wrapper > .Dashboard_Grids > .Dashboard_Grid_Container.Top > .Dashboard_Grid_Card.Welcome > .Weclome_Msg > .Name {
        font-size: 18px;
    }

    #Dashboard > .wrapper{
        padding: 20px 5px;
    }
}