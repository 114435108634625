

.Menu_Icons{
    color: var(--light-black);
}

#Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color:var(--bg-white);
    height: 64px;
    width: 100%;
}

#Navbar > .Sidebar_Toggle {
    color: var(--stroke);
    opacity: 0;
}

#Navbar > .Sidebar_Toggle:hover {
    color: var(--orange);
}

/* Date and Profile */
#Navbar > .Navbar_Date_and_Profile{
    display: flex;
    align-items: center;
    text-align: center;
}

#Navbar > .Navbar_Date_and_Profile .Navbar_Notifications {
    color: var(--pantone-black);
    margin-right: 10px;
    cursor: pointer;
    transition: hover 250ms ease-in-out;
}

#Navbar > .Navbar_Date_and_Profile .Navbar_Notifications:hover .icon{
    animation: ring 0.3s infinite alternate;
    color: #FF8911;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-15deg);
    }
}

#Navbar > .Navbar_Date_and_Profile .Navbar_Date {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg2-white);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    min-width: 150px;
    
}

#Navbar > .Navbar_Date_and_Profile .Navbar_Date > .Date_time {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

#Navbar > .Navbar_Date_and_Profile .Navbar_Date > .Date_time > .dot{
    font-size: 20px;
    font-weight: bold;
}

#Navbar > .Navbar_Date_and_Profile .Navbar_Date_and_Profile_Role {
    color: var(--orange);
    font-size: 16px;
    font-weight: bold;
}

/* Menu */
#Navbar_Menu .Menu_Item{
    border-radius: 5px;
    margin: 5px 10px;
    transition: all 250ms ease-in-out;
}

#Navbar_Menu .Menu_Item:hover {
    background-color: var(--bg2-white) !important;
}

.Menu_Profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding: 20px 10px;
}

.Menu_Profile > .Profile_Avatar{
    margin-bottom: 20px;
}

.Menu_Profile > .Profile_Avatar > span > .Avatar{
    width: 70px;
    height: 70px;
    margin: 0;
}

.Menu_Profile > .Profile_Name_Role{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Menu_Profile > .Profile_Name_Role > .Profile_Name {
    color: var(--light-black);
    margin-bottom: 5px;

}

.Menu_Profile > .Profile_Name_Role > .Profile_Role{
    font-size: 12px;
    text-align: center;
    color: white;
    background-color: var(--orange);
    padding: 5px 10px;
    min-width: 100px;
    border-radius: 20px;
}

/* Notifications */

#Navbar_Notification_Menu .Menu_Item_Notification{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    height: 60px;
    width: 310px;
    margin: 3px 8px;
    padding: 0px 5px;
    transition: all 250ms ease-in-out;
}

#Navbar_Notification_Menu .Menu_Item_Notification:hover {
    background-color: var(--bg2-white) !important;
}

#Navbar_Notification_Menu .Notification_Empty{
    width: 310px;
    height: 100%;
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Navbar_Notification_Menu .Notification_Empty > p{
    font-size: 14px;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_Icon{
    background-color: var(--light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_Icon > .Menu_Icons{
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_Text{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_Text > .Notification_Date{
    color: var(--dark-grey);
    font-size: 10px;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_Text > .Notification_Label{
    color: var(--orange);
    font-size: 14px;
    font-weight: bold;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_Text > .Notification_Details{
    font-size: 12px;
    width: 100%;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_Text > .Notification_Details > p{
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_IsRead{
    height: 100%;
    display: flex;
    align-items: center;
}

#Navbar_Notification_Menu .Menu_Item_Notification > .Notification_IsRead > span{
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--orange);
    border-radius: 50%;
}

@media only screen and (max-width: 1280px) {
    #Navbar > .Sidebar_Toggle {
        opacity: 1;
    }
}

@media only screen and (max-width: 500px) {
    #Navbar{
        padding: 10px 10px;
        height: 100px;
        display: block;
    }
    
    #Navbar > .Sidebar_Toggle {
        opacity: 1;
    }

    #Navbar > .Navbar_Date_and_Profile{
        width: 100%;
        justify-content: center;
    }
    
    #Navbar > .Navbar_Date_and_Profile .Navbar_Date > .Date_time {
        width: 160px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 375px) {
    #Navbar > .Sidebar_Toggle {
        opacity: 1;
    }

    #Navbar > .Navbar_Date_and_Profile{
        width: 100%;
        justify-content: center;
    }
    
    #Navbar > .Navbar_Date_and_Profile .Navbar_Date > .Date_time {
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;
        width: 160px;
    }

    #Navbar > .Navbar_Date_and_Profile .Navbar_Date > .Date_time > .dot{
        display: none;
    }
}

@media only screen and (max-width: 320px) {
    #Navbar > .Navbar_Date_and_Profile .Navbar_Date > .Date_time {
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 5px;
        font-size: 10px;
    }

    #Navbar > .Navbar_Date_and_Profile{
        width: 100%;
        justify-content: center;
    }
}