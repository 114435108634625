#UsersList > .wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
  gap: 30px;
}

#UsersList > .wrapper > .UsersList_Container {
  width: 100%;
  height: 800px;
  border-radius: 5px;
  background-color: white;
  padding: 20px 24px;
  box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
}
