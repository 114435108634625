
#Page_Not_Found{
    width: 100vw;
    height: 100vh;
    padding: 30px 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Page_Not_Found > .Page_Not_Found_Header {
    width: 100%;
}

#Page_Not_Found > .Page_Not_Found_Header > img {
    width: 50px;
    height: 50px;
}

#Page_Not_Found > .Page_Not_Found_Body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100%;
    gap: 40px;
}

#Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_Image > img{
    width: 400px;
}

#Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_Label{
    text-align: center;
}

#Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_Label > h1{
    margin: 10px 0;
}

#Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_Label > p{
    font-size: 15px;
}

#Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_GoBack{
    width: 100%;
}

#Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_GoBack > button{
    margin: 20px 0;
    width: 100%;
    height: 40px;
    background-color: var(--pantone-black);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 250ms ease-in-out;
}

#Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_GoBack > button:hover{
    background-color: var(--light-black);
}



@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 912px) {

}

@media only screen and (max-width: 540px) {
    #Page_Not_Found{
        padding: 10px 20px;
    }

    #Page_Not_Found > .Page_Not_Found_Body{
        width: 350px;
    }

    #Page_Not_Found > .Page_Not_Found_Body > .Page_Not_Found_Image > img{
        width: 300px;
    }
}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 320px) {

}