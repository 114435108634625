

#Requests_Table{
    width: 100%;
    height: 100%;
}

#Requests_Table > .wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#Requests_Table > .wrapper > .Table_Top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Left{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Left > *{
    display: flex;
    justify-content: center;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Left > .Legend{
    display: flex;
    justify-content: center;
    gap: 30px;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Left > .Legend > .Legend_Item{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Left > .Legend > .Legend_Item > p {
    color: var(--light-black);
    font-size: 14px;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Left > .Legend > .Legend_Item > span{
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: var(--error-red);
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Left > .Legend > .Legend_Item > span.Unread{
    background-color: var(--yellow);
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Right{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Right > *{
    display: flex;
    justify-content: center;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Right > button{
    height: 40px;
    padding: 10px 20px;
    border: none;
    background-color: var(--orange);
    color: white;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0 4px 2px -2px rgba(187, 187, 187, 0.5);
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#Requests_Table > .wrapper > .Table_Top > .Table_Top_Right > button:hover{
    background-color: var(--dark-orange);
}

/* Table */

#Requests_Table > .wrapper > .Table_Container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
}

#Requests_Table > .wrapper > .Table_Container > .Table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Empty{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#Requests_Table > .wrapper > .Table_Container > .Table  > .Table_Empty > .Empty_Image > img{
    width: 350px;
    object-fit: contain;
}

#Requests_Table > .wrapper > .Table_Container > .Table  > .Table_Empty > .Empty_Labels{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#Requests_Table > .wrapper > .Table_Container > .Table  > .Table_Empty > .Empty_Labels > .Main_Label{
    font-size: 22px;
    font-weight: bold;
    color: var(--light-black);
}

#Requests_Table > .wrapper > .Table_Container > .Table  > .Table_Empty > .Empty_Labels > .Sub_Label{
    font-size: 14px;
    font-weight: 300;
    color: var(--dark-grey);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 30px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header > .Table_Header_Label{
    width: 123px;
    font-size: 15px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header > .Table_Header_Filter{
    color: var(--light-grey);
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.Table_Header_Filter:hover{
    color: var(--orange) !important;
}

.Table_Header_Filter.active{
    color: var(--orange) !important;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container {
    width: 100%;
    height: 550px;
    overflow-y: auto;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: rgba(187, 187, 187, 0.25);
    height: 50px;
    border-radius: 5px;
    margin-bottom: 10px;
    user-select: text;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row.unread > .Table_Body_Details{
    border: 2px solid var(--yellow);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row.urgent > .Table_Body_Details{
    border: 2px solid var(--error-red);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row.unread.urgent > .Table_Body_Details{
    border: 2px solid var(--error-red);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details:hover{
    background-color: var(--light-grey);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Row_Container_Mobile > .Table_Row.unread{
    border: 2px solid var(--yellow);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Row_Container_Mobile > .Table_Row.urgent{
    border: 2px solid var(--error-red);
}


#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > div p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    width: 145px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Actions {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Actions > .View{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    background-color: var(--orange);
    border-radius: 5px;
    cursor: pointer;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Actions > .Edit{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    background-color: #4682B4;
    border-radius: 5px;
    cursor: pointer;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Actions > .Archive{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    background-color: #93C572;
    border-radius: 5px;
    cursor: pointer;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Actions > .Approve{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    background-color: var(--green);
    border-radius: 5px;
    cursor: pointer;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Actions > .Reject{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    background-color: var(--red);
    border-radius: 5px;
    cursor: pointer;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Status{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Status > p {
    display: flex;
    background-color: var(--blue);
    width: 120px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Status.Approved > p{
    background-color: var(--green);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Status.Rejected > p{
    background-color: var(--error-red);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .Status.Ongoing > p{
    background-color: var(--yellow);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    box-shadow: -5px 10px 20px 0 rgba(187, 187, 187, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    color: var(--dark-grey);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details{
    display: flex;
    gap: 10px;
    white-space: initial;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > span{
    width: 100px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > p{
    color: var(--pantone-black);
    width: 1000px;
    word-wrap: break-word;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker{
    display: flex;
    gap: 5px;
    width: 1000px;
    height: 140px;
    padding: 10px 20px;
    background-color: var(--bg-white);
    border: 1px dotted var(--dark-grey);
    border-radius: 5px;
    overflow-x: auto;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Tracker_Add{
    height: 100%;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Tracker_Add > span{
    color: var(--dark-grey);
    cursor: pointer;
    font-size: 16px;
    transition: all 250ms ease-in-out;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Tracker_Add > span:hover{
    color: var(--orange);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item{
    display: flex;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Tracker_Details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Tracker_Details > .Signature > img{
    width: 80px;
    object-fit: contain;
    height: 40px;
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Tracker_Details > .Tracker_Date{
    font-size: 11px;
    color: var(--orange);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Tracker_Details > .Tracker_Label{
    font-size: 14px;
    width: 100px;
    height: 2rem;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    color: var(--light-black);
}

#Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker > .Tracker_Item > .Right_Arrow{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Pagination */

#Requests_Table > .wrapper > .Table_Pagination{
    width: 100%;
}

#Requests_Table > .wrapper > .Table_Pagination{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

#Requests_Table > .wrapper > .Table_Pagination > button {
    width: 100px;
    height: 35px;
}

#Requests_Table > .wrapper > .Table_Pagination > .Pagination_Previous{
    border: 1px solid var(--light-black);
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#Requests_Table > .wrapper > .Table_Pagination > .Pagination_Previous:hover{
    background-color: var(--light-grey);
    border-color: var(--light-grey);
}

#Requests_Table > .wrapper > .Table_Pagination > .Pagination_Next{
    border: 1px solid var(--pantone-black);
    background-color: var(--pantone-black);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#Requests_Table > .wrapper > .Table_Pagination > .Pagination_Next:hover{
    background-color: var(--light-black);
    border-color: var(--light-black);
}

@media only screen and (max-width: 1440px) {
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .ReceivedBy {
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header.ReceivedBy{
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container{
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 1368px) {
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .ReceivedBy {
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header.ReceivedBy{
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > p{
        width: 750px;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .OfficeDept {
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header.OfficeDept{
        display: none;
    }

    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker{
        width: 770px;
    }
}

@media only screen and (max-width: 1280px) {
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .ReceivedBy {
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header.ReceivedBy{
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header.OfficeDept{
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header.OfficeDept{
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .OfficeDept {
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > p{
        width: 700px;
    }
}

@media only screen and (max-width: 912px) {
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details > .DateReceived {
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row > .Table_Body_Details{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container > .Tabler_Header.DateReceived{
        display: none;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Header_Container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > p{
        width: 500px;
    }
    #Requests_Table > .wrapper > .Table_Container > .Table > .Table_Body_Container > .Table_Body_Row .Table_Body_Other_Details > .Other_Details > .Tracker{
        width: 510px;
    }
}

@media only screen and (max-width: 540px) {

    #Requests_Table > .wrapper > .Table_Top{
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    #Requests_Table > .wrapper > .Table_Top > .Table_Top_Left{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
    }
    #Requests_Table > .wrapper > .Table_Top > .Table_Top_Right{
        width: 100%;
        flex-direction: column;
    }
    #Requests_Table > .wrapper > .Table_Top > .Table_Top_Right > button{
        width: 100%;
    }

    #Requests_Table .Table_Row_Container_Mobile{
        width: 100%;
        height: 500px;
        overflow-y: auto;
    }

    #Requests_Table .Table_Empty{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }

    #Requests_Table .Table_Empty > .Empty_Image{
        text-align: center;
    }

    #Requests_Table .Table_Empty > .Empty_Image > img{
        width: 225px;
        height: 225px;
    }

    #Requests_Table .Table_Empty > .Empty_Labels{
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }

    #Requests_Table .Table_Empty > .Empty_Labels > span{
        width: 100%;
        text-align: center;
    }

    #Requests_Table .Table_Empty > .Empty_Labels > .Main_Label{
        font-weight: bold;
        font-size: 18px;
    }

    #Requests_Table .Table_Empty > .Empty_Labels > .Sub_Label{
        color: var(--dark-grey);
        font-size: 13px;
    }

    #Requests_Table .Table_Row{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F4F4F4;
        padding: 10px 6px;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    #Requests_Table .Table_Row > .Table_Header_Container{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header{
        display: flex;
        align-items: center;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > span{
        width: 45%;
        color: var(--dark-grey);
    }
    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > p{
        color: var(--light-black);
        width: 55%;
        word-wrap: break-word;
    }
    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Status{
        color: #4682B4;
    }
    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Status.Approved{
        color: #93C572;
    }
    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Status.Ongoing{
        color: #FFC300;
    }
    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Status.Status.Rejected{
        color: #FA5F55;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Actions{
        display: flex;
        width: 55%;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Actions > .Action{
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Actions > .Action.View{
        background-color: var(--orange);
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Actions > .Action.Edit{
        background-color: #4682B4;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Actions > .Action.Archive{
        background-color: #93C572;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Actions > .Action.Approve{
        background-color: var(--green);
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > .Actions > .Action.Reject{
        background-color: var(--red);
    }

}

@media only screen and (max-width: 390px) {
    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header{
        flex-direction: column;
    }

    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > span{
        width: 100%;
        font-size: 15px;
    }
    #Requests_Table .Table_Row > .Table_Header_Container > .Tabler_Header > p{
        width: 100%;
        font-size: 15px;
    }
}

@media only screen and (max-width: 320px) {
    #Requests_Table .Table_Empty > .Empty_Image > img{
        width: 180px;
        height: 180px;
    }

    #Requests_Table .Table_Empty > .Empty_Labels > .Main_Label{
        font-weight: bold;
        font-size: 14px;
    }

    #Requests_Table .Table_Empty > .Empty_Labels > .Sub_Label{
        color: var(--dark-grey);
        font-size: 11px;
    }
}

